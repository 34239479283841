import { Directive, inject, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[here-map-marker]'
})
export class HereMapMarkerDirective {
  public template = inject(TemplateRef);
  public viewContainer = inject(ViewContainerRef);
}

@Directive({
  standalone: true,
  selector: '[here-map-cluster]'
})
export class HereMapClusterDirective {
  public template = inject(TemplateRef);
}

@Directive({
  standalone: true,
  selector: '[marker-container]'
})
export class HereMapMarkerContainerDirective {
  public viewContainer = inject(ViewContainerRef);
}
