// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-longer-valid-container {
  line-height: 28px;
}

.icon-padding {
  padding-right: 40px;
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/booking-overview/components/no-longer-valid/no-longer-valid.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF","sourcesContent":[".no-longer-valid-container {\n  line-height: 28px;\n}\n\n.icon-padding {\n  padding-right: 40px;\n  font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
