// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Colors as defined in uxpin
 * https://app.uxpin.com/design-system/06f70c80e5f566b5ec18/colors
 */
ks-main-content {
  margin-top: calc(35px + 29px);
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/variables.scss","webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;;;EAAA;ACEA;EACI,6BAAA;AAGJ","sourcesContent":["/*\n * Colors as defined in uxpin\n * https://app.uxpin.com/design-system/06f70c80e5f566b5ec18/colors\n */\n\n$uxpin-white-color: #ffffff;\n$uxpin-default-color: #94999d;\n$uxpin-info-color: #4a8ba4;\n$uxpin-black-color: #242424;\n$tab-border: #ddd;\n\n// INTERACTION COLORS\n$interaction-warning: #f2855f;\n\n// MAIN BRAND COLORS\n$color-brand-01: #041e42;\n$color-brand-03: #d6001c;\n\n// font families\n$scania-condensed-bold: 'Scania Sans Semi Condensed Bold';\n$scania-condensed: 'Scania Sans Semi Condensed';\n$scania-headline: 'Scania Sans Headline';\n\n//margin-top after new nav bar\n$padding-top: 35px;\n\n//sdds variables\n$sdds-border-radius: 4px;\n$sdds-body-font-size: 14px;\n$sdds-body-font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;\n\n$sp-colors: (\n  danger: var(--sdds-red-600),\n  default: var(--sdds-grey-600),\n  info: var(--sdds-blue-400),\n  success: var(--sdds-positive),\n  warning: var(--sdds-warning),\n  primary: var(--sdds-blue-600)\n);\n","@import 'variables.scss';\n\nks-main-content {\n    margin-top: calc(#{$padding-top} + 29px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
