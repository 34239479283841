import { NgModule } from '@angular/core';
import { HereMapWorkshopMarkerComponent } from '@shared/components/here-map-markers/here-map-workshop-marker.component';
import { HereMapClusterDirective, HereMapMarkerDirective } from '@shared/components/here-map/here-map-marker.directive';
import { HereMapComponent } from '@shared/components/here-map/here-map.component';

@NgModule({
  imports: [HereMapComponent, HereMapMarkerDirective, HereMapClusterDirective, HereMapWorkshopMarkerComponent],
  exports: [HereMapComponent, HereMapMarkerDirective, HereMapClusterDirective, HereMapWorkshopMarkerComponent]
})
export class HereMapModule {}
