// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .custom-marker-container {
      position: relative;
      cursor: pointer;
      /** bottom center as marker origin */
      transform: translate(-50%, -100%);

      .custom-marker-tooltip-container {
        display: none;
        -webkit-user-select: none;
                user-select: none;
        background: var(--sdds-white);
        padding: 0.5rem;
        position: absolute;
        word-break: keep-all;
        left: calc(100% + 1rem);
        top: 0;
        white-space: nowrap;
        font-size: 1.2rem;
        color: var(--sdds-grey-958);
        font-family: var(--sdds-font-family-semi-condensed);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0.4rem;
      }

      #icon-container,
      #icon {
        transition: all 0.2s cubic-bezier(0.1, 0.9, 0.2, 1);
      }

      #icon-container {
        fill: var(--sdds-blue-600);
        stroke: white;
      }

      #icon {
        fill: white;
      }

      &:hover {
        .custom-marker-tooltip-container {
          display: block;
        }
      }
    }

    .custom-marker-container:hover,
    .here-map-marker-selected .custom-marker-container {
      #icon-container {
        fill: white;
        stroke: transparent;
      }

      #icon {
        fill: var(--sdds-blue-400);
      }
    }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/components/here-map-markers/here-map-workshop-marker.component.ts"],"names":[],"mappings":";IACI;MACE,kBAAkB;MAClB,eAAe;MACf,oCAAoC;MACpC,iCAAiC;;MAEjC;QACE,aAAa;QACb,yBAAiB;gBAAjB,iBAAiB;QACjB,6BAA6B;QAC7B,eAAe;QACf,kBAAkB;QAClB,oBAAoB;QACpB,uBAAuB;QACvB,MAAM;QACN,mBAAmB;QACnB,iBAAiB;QACjB,2BAA2B;QAC3B,mDAAmD;QACnD,+CAA+C;QAC/C,qBAAqB;MACvB;;MAEA;;QAEE,mDAAmD;MACrD;;MAEA;QACE,0BAA0B;QAC1B,aAAa;MACf;;MAEA;QACE,WAAW;MACb;;MAEA;QACE;UACE,cAAc;QAChB;MACF;IACF;;IAEA;;MAEE;QACE,WAAW;QACX,mBAAmB;MACrB;;MAEA;QACE,0BAA0B;MAC5B;IACF","sourcesContent":["\n    .custom-marker-container {\n      position: relative;\n      cursor: pointer;\n      /** bottom center as marker origin */\n      transform: translate(-50%, -100%);\n\n      .custom-marker-tooltip-container {\n        display: none;\n        user-select: none;\n        background: var(--sdds-white);\n        padding: 0.5rem;\n        position: absolute;\n        word-break: keep-all;\n        left: calc(100% + 1rem);\n        top: 0;\n        white-space: nowrap;\n        font-size: 1.2rem;\n        color: var(--sdds-grey-958);\n        font-family: var(--sdds-font-family-semi-condensed);\n        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);\n        border-radius: 0.4rem;\n      }\n\n      #icon-container,\n      #icon {\n        transition: all 0.2s cubic-bezier(0.1, 0.9, 0.2, 1);\n      }\n\n      #icon-container {\n        fill: var(--sdds-blue-600);\n        stroke: white;\n      }\n\n      #icon {\n        fill: white;\n      }\n\n      &:hover {\n        .custom-marker-tooltip-container {\n          display: block;\n        }\n      }\n    }\n\n    .custom-marker-container:hover,\n    .here-map-marker-selected .custom-marker-container {\n      #icon-container {\n        fill: white;\n        stroke: transparent;\n      }\n\n      #icon {\n        fill: var(--sdds-blue-400);\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
