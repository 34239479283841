// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
}

.map-container {
  height: 100%;
  width: 100%;
}

::ng-deep .here-map-cluster-marker-container,
::ng-deep .here-map-marker-container {
  -webkit-user-select: none;
          user-select: none;
}
::ng-deep .here-map-marker-container:hover {
  z-index: 9999 !important;
}
::ng-deep .default-cluster-icon-container {
  cursor: pointer;
  border-radius: 100%;
  background-color: var(--sdds-white);
  border: 2px solid var(--sdds-blue-800);
  color: var(--sdds-blue-800);
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
::ng-deep .default-cluster-icon-container:hover {
  border-color: var(--sdds-blue-400);
  color: var(--sdds-blue-400);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/here-map/here-map.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAGE;;EAEE,yBAAA;UAAA,iBAAA;AAAJ;AAGE;EACE,wBAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,mCAAA;EACA,sCAAA;EACA,2BAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAII;EACE,kCAAA;EACA,2BAAA;AAFN","sourcesContent":[":host {\n  height: 100%;\n  width: 100%;\n}\n\n.map-container {\n  height: 100%;\n  width: 100%;\n}\n\n::ng-deep {\n  .here-map-cluster-marker-container,\n  .here-map-marker-container {\n    user-select: none;\n  }\n\n  .here-map-marker-container:hover {\n    z-index: 9999 !important;\n  }\n\n  .default-cluster-icon-container {\n    cursor: pointer;\n    border-radius: 100%;\n    background-color: var(--sdds-white);\n    border: 2px solid var(--sdds-blue-800);\n    color: var(--sdds-blue-800);\n    height: 4rem;\n    width: 4rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &:hover {\n      border-color: var(--sdds-blue-400);\n      color: var(--sdds-blue-400);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
