import { EquipmentDetails } from '@admin-facade/models/equipment-data.model';
import { Job } from './job.model';

export interface BookingDetails {
  jobs: Job[];
  pickUpTime: string;
  dropOffTime: string;
  workshopInfo: ScaniaWorkshop;
  equipment: EquipmentDetails;
  bookingSubStatus: string;
  messageFromWorkshop: string;
  bookingReference: string;
}

export interface ScaniaWorkshop {
  name: string;
  email: string;
  address: Address;
  location: Location;
  phone: string;
}

interface Address {
  street: string;
  city: string;
  postalCode: string;
}

interface Location {
  longitude: number;
  latitude: number;
}

export enum BookingStatus {
  BOOKED = 'Booked',
  PRELIMINARY = 'Preliminary',
  PERFORMED = 'Performed'
}

export enum BookingSubStatus {
  PROPOSAL = 'Proposal',
  ACCEPTED = 'Accepted'
}
