import {
  Component,
  contentChild,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  viewChild
} from '@angular/core';
import H from '@here/maps-api-for-javascript';
import {
  HereMapClusterDirective,
  HereMapMarkerContainerDirective,
  HereMapMarkerDirective
} from '@shared/components/here-map/here-map-marker.directive';
import { HereMapLocation, HereMapService } from '@shared/components/here-map/here-map.service';

@Component({
  selector: 'here-map',
  standalone: true,
  imports: [HereMapMarkerContainerDirective],
  providers: [HereMapService],
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.scss']
})
export class HereMapComponent<T> implements OnInit, OnDestroy {
  private hereMapService = inject(HereMapService);

  mapContainer = viewChild<ElementRef>('mapContainer');
  markerContainerHost = viewChild(HereMapMarkerContainerDirective);
  clusterTemplate = contentChild(HereMapClusterDirective);
  markerTemplate = contentChild(HereMapMarkerDirective);

  locations = input<HereMapLocation<T>[]>([]);
  clustering = input<boolean>(false);
  center = input<HereMapLocation<T>>();

  locationSelected = output<T>();

  private map: H.Map;

  private markers: H.map.Marker[] = [];

  private clusterLayer: H.map.layer.ObjectLayer;

  private resizeObserver: ResizeObserver;

  // update locations when loactions change
  private locationsChangeEffect = effect(() => this.locations() && this.updateLocations());
  // update center of map when center changes
  private centerChangeEffect = effect(() => this.center() && this.updateCenter());

  private resizeHandler = () => this.map?.getViewPort().resize();

  ngOnInit(): void {
    this.map = this.hereMapService.createMap(this.mapContainer().nativeElement, this.getMapCenter());
    this.registerResizeHandler();
    this.updateLocations();
  }

  private getMapCenter(): H.geo.IPoint {
    const center = this.center();

    if (center) {
      return center;
    }

    // Center around Stockholm
    return { lat: 58.46, lng: 17.61 };
  }

  private createMarker(location: HereMapLocation, minZoom?: number): H.map.Marker {
    let marker: H.map.Marker;

    const markerTemplateHost = this.markerTemplate();
    if (markerTemplateHost) {
      const templateRef = markerTemplateHost.template;
      const viewContainer = markerTemplateHost.viewContainer;
      marker = this.hereMapService.createCustomMarker(location, templateRef, viewContainer, minZoom);
    } else {
      marker = this.hereMapService.createDefaultMarker(location, minZoom);
    }

    marker.addEventListener('tap', () => {
      this.locationSelected.emit(location.data);
      this.map.getViewModel().setLookAtData({ position: location }, true);
    });
    return marker;
  }

  private createClusterMarker(cluster: H.clustering.ICluster): H.map.Marker {
    let clusterMarker: H.map.DomMarker;

    const clusterTemplateHost = this.clusterTemplate();
    if (clusterTemplateHost) {
      const viewContainer = this.markerContainerHost().viewContainer;
      const template = clusterTemplateHost.template;
      clusterMarker = this.hereMapService.createCustomClusterMarker(cluster, template, viewContainer);
    } else {
      clusterMarker = this.hereMapService.createDefaultClusterMarker(cluster);
    }

    clusterMarker.addEventListener('tap', () =>
      // animate zoom and center so we can reveal more items when clicking on a cluster
      this.map.getViewModel().setLookAtData({ position: cluster.getPosition(), zoom: cluster.getMaxZoom() + 1 }, true)
    );

    return clusterMarker;
  }

  private updateCenter() {
    if (!this.map) {
      return;
    }

    this.map.getViewModel().setLookAtData({ position: this.getMapCenter() }, true);
  }

  updateLocations() {
    if (!this.map) {
      return;
    }

    this.markerContainerHost().viewContainer.clear();

    if (this.clustering()) {
      this.updateClusters();
    } else {
      this.updateMarkers();
    }
  }

  updateMarkers() {
    this.markers.forEach((marker) => {
      this.map.removeObject(marker);
      marker.dispose();
    });

    this.markers = this.locations().map((location) => this.createMarker(location));
    this.map.addObjects(this.markers);
  }

  updateClusters() {
    if (this.clusterLayer) {
      this.map.removeLayer(this.clusterLayer);
      this.clusterLayer.dispose();
    }

    this.clusterLayer = this.hereMapService.createClusterLayer(this.locations(), {
      getClusterPresentation: (cluster): H.map.Object => this.createClusterMarker(cluster),
      getNoisePresentation: (dataPoint): H.map.Object => this.createMarker(dataPoint.getData(), dataPoint.getMinZoom())
    });

    this.map.addLayer(this.clusterLayer);
  }

  private registerResizeHandler() {
    this.resizeObserver = new ResizeObserver(this.resizeHandler);
    this.resizeObserver.observe(this.mapContainer().nativeElement);
    window.addEventListener('resize', this.resizeHandler);
  }

  ngOnDestroy(): void {
    this.locationsChangeEffect.destroy();
    this.centerChangeEffect.destroy();

    if (this.map) {
      this.map.dispose();
    }

    this.markerContainerHost().viewContainer.clear();

    this.resizeObserver.disconnect();
    window.removeEventListener('resize', this.resizeHandler);
  }
}
