import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


export enum MaintenancePlan {
  FLEX = 'FLEX',
  PROCARE = 'PROCARE'
}

@Pipe({ name: 'serviceEventHeaderJob' })
export class ServiceEventHeaderJobPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(header: string): string {
    switch (header) {
      case MaintenancePlan.FLEX:
        return this.translate.instant('FlexibeltUnderhåll');
      case MaintenancePlan.PROCARE:
        return this.translate.instant('ProCareUnderhåll');
      default:
        return header;
    }
  }
}
