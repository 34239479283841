export interface MapLanguage {
  Culture: string;
  HereUiCode: string;
  HereCode: string;
  Label: string;
  Subtag: string;
}

export const MAP_LANGUAGES: MapLanguage[] = [
  { Culture: 'en-GB', HereUiCode: 'en-US', HereCode: 'ENG', Label: 'English', Subtag: 'en' },
  { Culture: 'de-DE', HereUiCode: 'de-DE', HereCode: 'GER', Label: 'German', Subtag: 'de' },
  { Culture: 'es-ES', HereUiCode: 'es-ES', HereCode: 'SPA', Label: 'Spanish', Subtag: 'es' },
  { Culture: 'fr-FR', HereUiCode: 'fr-FR', HereCode: 'FRE', Label: 'French', Subtag: 'fr' },
  { Culture: 'nl-NL', HereUiCode: 'nl-NL', HereCode: 'DUT', Label: 'Dutch', Subtag: 'nl' },
  { Culture: 'pl-PL', HereUiCode: 'pl-PL', HereCode: 'POL', Label: 'Polish', Subtag: 'pl' },
  {
    Culture: 'pt-PT',
    HereUiCode: 'pt-PT',
    HereCode: 'POR',
    Label: 'Portuguese',
    Subtag: 'pt'
  },
  { Culture: 'tr-TR', HereUiCode: 'tr-TR', HereCode: 'TUR', Label: 'Turkish', Subtag: 'tr' },
  { Culture: 'ru-RU', HereUiCode: 'ru-RU', HereCode: 'RUS', Label: 'Russian', Subtag: 'ru' },
  {
    Culture: 'zh-CN',
    HereUiCode: 'zh-CN',
    HereCode: 'CHI',
    Label: 'Simplified chinese',
    Subtag: 'zh'
  },
  {
    Culture: 'zh-TW',
    HereUiCode: '',
    HereCode: 'CHT',
    Label: 'Traditional chinese',
    Subtag: 'zh'
  },
  { Culture: 'it-IT', HereUiCode: 'it-IT', HereCode: 'ITA', Label: 'Italian', Subtag: 'it' },
  { Culture: 'gd-Latn', HereUiCode: '', HereCode: 'GLE', Label: 'Gaelic', Subtag: 'gd' },
  { Culture: 'el-GR', HereUiCode: '', HereCode: 'GRE', Label: 'Greek', Subtag: 'el' },
  { Culture: 'he-IL', HereUiCode: '', HereCode: 'HEB', Label: 'Hebrew', Subtag: 'he' },
  { Culture: 'hi-IN', HereUiCode: '', HereCode: 'HIN', Label: 'Hindi', Subtag: 'hi' },
  { Culture: 'id-ID', HereUiCode: '', HereCode: 'IND', Label: 'Indonesian', Subtag: 'id' },
  { Culture: 'fa-IR', HereUiCode: '', HereCode: 'PER', Label: 'Persian', Subtag: 'fa' },
  { Culture: '', HereUiCode: '', HereCode: 'SIN', Label: 'Sinhalese', Subtag: '' },
  { Culture: 'th-TH', HereUiCode: '', HereCode: 'THA', Label: 'Thai', Subtag: 'th' },
  { Culture: 'uk-UA', HereUiCode: '', HereCode: 'UKR', Label: 'Ukrainian', Subtag: 'uk' },
  { Culture: 'ur-PK', HereUiCode: '', HereCode: 'URD', Label: 'Urdu', Subtag: 'ur' },
  { Culture: 'vi-VN', HereUiCode: '', HereCode: 'VIE', Label: 'Vietnamese', Subtag: 'vi' },
  { Culture: 'cy-GB', HereUiCode: '', HereCode: 'WEL', Label: 'Welsh', Subtag: 'cy' },
  { Culture: 'ar-SA', HereUiCode: '', HereCode: 'ARA', Label: 'Arabic', Subtag: 'ar' },
  { Culture: 'ja-JP', HereUiCode: '', HereCode: 'JPN', Label: 'Japanese', Subtag: 'ja' },
  { Culture: 'bg-BG', HereUiCode: '', HereCode: 'BUL', Label: 'Bulgarian', Subtag: 'bg' },
  { Culture: 'sv-SE', HereUiCode: '', HereCode: 'SWE', Label: 'Swedish', Subtag: 'sv' },
  { Culture: 'fi-FI', HereUiCode: '', HereCode: 'FIN', Label: 'Finnish', Subtag: 'fi' },
  { Culture: 'sr-RS', HereUiCode: '', HereCode: 'SRP', Label: 'Serbian', Subtag: 'sr' },
  { Culture: 'ro-RO', HereUiCode: '', HereCode: 'RON', Label: 'Romanian', Subtag: 'ro' },
  { Culture: 'pt-BR', HereUiCode: '', HereCode: 'POR', Label: 'PortoBras', Subtag: 'pt' },
  { Culture: 'nb-NO', HereUiCode: '', HereCode: 'NOR', Label: 'Norwegian', Subtag: 'nb' },
  { Culture: 'hu-HU', HereUiCode: '', HereCode: 'HUN', Label: 'Hungarian', Subtag: 'hu' },
  { Culture: 'lt-LT', HereUiCode: '', HereCode: 'LIT', Label: 'Lithuanian', Subtag: 'lt' },
  { Culture: 'ko-KR', HereUiCode: '', HereCode: 'KOR', Label: 'Korean', Subtag: 'ko' },
  { Culture: 'et-EE', HereUiCode: '', HereCode: 'EST', Label: 'Estonian', Subtag: 'et' },
  { Culture: 'da-DK', HereUiCode: '', HereCode: 'DAN', Label: 'Danish', Subtag: 'da' },
  { Culture: 'cs-CZ', HereUiCode: '', HereCode: 'CES', Label: 'Czech', Subtag: 'cs' },
  { Culture: 'lv-LV', HereUiCode: '', HereCode: 'LVA', Label: 'Latvian', Subtag: 'lv' },
  { Culture: 'sl-SI', HereUiCode: '', HereCode: 'SVN', Label: 'Slovenian', Subtag: 'sl' },
  { Culture: 'hr-HR', HereUiCode: '', HereCode: 'HRV', Label: 'Croatian', Subtag: 'hr' }
];

export const MAP_TILE = {
  size: 512,
  ppi: 72
};

export const SCALEBAR_STYLE = {
  position: 'absolute',
  bottom: '2rem',
  right: '2rem'
};

export const MAP_ENGINE_TYPE = H.Map.EngineType.HARP;

export const DEFAULT_ICON_SVG = `
  <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.64 28.02L17.5525 37.2867C17.3349 37.4866 17.0489 37.5977 16.7519 37.5977C16.455 37.5977 16.169 37.4866 15.9514 37.2867L5.86391 28.02C-1.41355 21.3347 -0.83924 9.77462 7.06582 3.82728C12.7895 -0.478885 20.7144 -0.478886 26.4381 3.82728C34.3431 9.77461 34.9175 21.3347 27.64 28.02Z" fill="#16417F"/>
    <path d="M26.502 16.4556C26.502 21.789 22.1388 26.1164 16.752 26.1164C11.3651 26.1164 7.00195 21.789 7.00195 16.4556C7.00195 11.1223 11.3651 6.79492 16.752 6.79492C22.1388 6.79492 26.502 11.1223 26.502 16.4556Z" stroke="white" stroke-width="0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3056 11.3176C20.3373 11.4807 20.2859 11.6489 20.1685 11.7664L17.6958 14.2391C17.3053 14.6296 17.3053 15.2629 17.696 15.6536C18.0868 16.0444 18.7201 16.0443 19.1106 15.6538L21.5832 13.1812C21.7007 13.0637 21.8689 13.0123 22.032 13.0441C22.1951 13.0759 22.3318 13.1866 22.3965 13.3396C23.0892 14.9754 22.7699 16.9392 21.4354 18.2737C20.1869 19.5222 18.3875 19.8821 16.8213 19.3561L14.1653 22.0122C13.3844 22.793 12.1185 22.7928 11.3377 22.012C10.5569 21.2311 10.5566 19.9652 11.3374 19.1844L13.9935 16.5283C13.4675 14.9621 13.8274 13.1627 15.0759 11.9142C16.4104 10.5798 18.3742 10.2605 20.01 10.9531C20.163 11.0179 20.2738 11.1545 20.3056 11.3176ZM18.8708 11.651C17.783 11.4574 16.622 11.7812 15.7825 12.6208C14.7456 13.6576 14.4954 15.1847 15.0349 16.4587C15.1142 16.6461 15.072 16.8629 14.9281 17.0068L12.044 19.8909C11.6535 20.2814 11.6535 20.9147 12.0442 21.3054C12.435 21.6962 13.0683 21.6961 13.4587 21.3057L16.3429 18.4215C16.4868 18.2776 16.7035 18.2354 16.8909 18.3148C18.1649 18.8542 19.692 18.604 20.7289 17.5672C21.5684 16.7276 21.8922 15.5666 21.6986 14.4788L19.8171 16.3604C19.0363 17.1412 17.7703 17.141 16.9895 16.3601C16.2087 15.5793 16.2085 14.3134 16.9893 13.5326L18.8708 11.651Z" fill="white"/>
  </svg>
`;
