import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HashRouteRedirectGuard implements CanActivate {
  canActivate(): boolean {
    // keep compatibility with hash routing and redirect to corresponding route without hashing
    if (window.location.hash.startsWith('#/')) {
      window.location.href = window.location.hash.slice(1);
      return null;
    }
    return true;
  }
}
