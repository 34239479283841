import { Component, Input } from '@angular/core';

export enum Status {
  DANGER = 'danger',
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}

@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DEFAULT_ICON = 'cui-scania-warning';
  @Input() icon: string;
  @Input() headerLabel: string;
  @Input() label: string;
  @Input() type: Status = Status.DEFAULT;
}
