import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import {
  ChecklistTableColumnDefDirective,
  ChecklistTableComponent
} from '@shared/components/checklist-table/checklist-table.component';
import { InformationComponent } from '@shared/components/information/information.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { MapComponent } from '@shared/components/map/map.component';
import { LocalizedDatePipe } from '@shared/pipes/localized-date/localized-date.pipe';
import { ButtonModule } from '@teamsp/components/button';
import { LoaderModule, LOADER_COMPONENT_TOKEN } from '@teamsp/components/loader';
import { GlobalErrorHandler } from './errors/error-handler';
import { BookingProposalPlaygroundInterceptor } from './http-interceptors/booking-proposal-playground-interceptor';

@NgModule({
  declarations: [
    CheckboxComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    InformationComponent,
    LoaderComponent,
    LocalizedDatePipe,
    MapComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    LoaderModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    NgbModule,
    OverlayModule,
    RouterModule,
    ScrollingModule,
    TranslateModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: BookingProposalPlaygroundInterceptor, multi: true },
    { provide: LOADER_COMPONENT_TOKEN, useValue: LoaderComponent },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DecimalPipe,
    TranslateService
  ],
  exports: [
    ButtonModule,
    CheckboxComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    InformationComponent,
    LoaderComponent,
    LoaderModule,
    LocalizedDatePipe,
    MapComponent,
    TranslateModule
  ]
})
export class SharedModule {}
