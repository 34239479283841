import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

export type ToastrCallback = (message: string, title: string, override?: Partial<IndividualConfig>) => ActiveToast<any>;

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {
  private successCB: ToastrCallback;
  private infoCB: ToastrCallback;
  private errorCB: ToastrCallback;
  private warningCB: ToastrCallback;

  constructor(private toastrService: ToastrService, private translate: TranslateService) {
    this.successCB = this.toastrService.success.bind(this.toastrService);
    this.infoCB = this.toastrService.info.bind(this.toastrService);
    this.errorCB = this.toastrService.error.bind(this.toastrService);
    this.warningCB = this.toastrService.warning.bind(this.toastrService);
  }

  success(message?: string, title?: string): void {
    this.invokeToastr(this.successCB, message, title);
  }

  info(message?: string, title?: string): void {
    this.invokeToastr(this.infoCB, message, title);
  }

  error(message?: string, title?: string): void {
    this.invokeToastr(this.errorCB, message, title);
  }

  warning(message?: string, title?: string): void {
    this.invokeToastr(this.warningCB, message, title);
  }

  genericError(): void {
    this.error('EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet');
  }

  invokeToastr(toastrFunc: ToastrCallback, message?: string, title?: string): void {
    message = message ? this.translate.instant(message) : '';
    title = title ? this.translate.instant(title) : '';
    toastrFunc(message, title, { onActivateTick: true });
  }
}
