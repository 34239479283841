/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoLongerValidComponent } from '@booking-overview/components/no-longer-valid/no-longer-valid.component';
import { NoNetworkComponent } from '@shared/components/no-network/no-network.component';
import { HashRouteRedirectGuard } from '@shared/guards/hash-route-redirect/hash-route-redirect.guard';
import { AppComponent } from './app.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'booking-proposal-info/:workOrderId',
    loadChildren: () => import('./booking-overview/booking-overview.module').then((m) => m.BookingOverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-confirmed-info',
    loadChildren: () => import('./booking-overview/booking-overview.module').then((m) => m.BookingOverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-reminder-info',
    loadChildren: () => import('./booking-overview/booking-overview.module').then((m) => m.BookingOverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-proposal/no-longer-valid',
    component: NoLongerValidComponent
  },
  {
    path: 'booking-proposal/playground',
    loadChildren: () => import('./booking-overview/booking-overview.module').then((m) => m.BookingOverviewModule)
  },
  {
    path: 'no-network',
    component: NoNetworkComponent
  },
  {
    /** check if the users enters a hashed route, which was the previous routing strategy
     * in which case we need to redirect them to the non-hashed corresponding route */
    path: '**',
    canActivate: [HashRouteRedirectGuard],
    component: AppComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
