import { Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'here-map-workshop-marker',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="custom-marker-container" [class.selected]="selected()">
      <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.648 27.4223L16.6505 36.689C16.4349 36.8889 16.1514 37 15.8571 37C15.5628 37 15.2794 36.8889 15.0638 36.689L5.06632 27.4223C-2.14617 20.737 -1.57699 9.17696 6.2575 3.22963C11.9301 -1.07654 19.7842 -1.07654 25.4568 3.22962C33.2913 9.17696 33.8605 20.737 26.648 27.4223Z"
          fill="var(--sdds-blue-600)"
        />
        <path
          id="icon-container"
          d="M25.5187 15.858C25.5187 21.1934 21.1934 25.5187 15.858 25.5187C10.5225 25.5187 6.19727 21.1934 6.19727 15.858C6.19727 10.5225 10.5225 6.19727 15.858 6.19727C21.1934 6.19727 25.5187 10.5225 25.5187 15.858Z"
          stroke-width="0.5"
        />

        <path
          id="icon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.3804 10.6959C19.4119 10.8576 19.3609 11.0243 19.2445 11.1407L16.794 13.5913C16.4069 13.9783 16.4069 14.606 16.7942 14.9932C17.1814 15.3805 17.8091 15.3804 18.1961 14.9934L20.6467 12.5429C20.7631 12.4264 20.9298 12.3755 21.0914 12.407C21.2531 12.4385 21.3885 12.5483 21.4527 12.6999C22.1392 14.3211 21.8227 16.2674 20.5001 17.5899C19.2628 18.8273 17.4795 19.184 15.9273 18.6627L13.2949 21.295C12.5211 22.0689 11.2664 22.0686 10.4926 21.2948C9.71877 20.5209 9.71853 19.2663 10.4924 18.4925L13.1247 15.8601C12.6034 14.3079 12.9601 12.5246 14.1975 11.2873C15.52 9.96469 17.4663 9.64823 19.0874 10.3347C19.2391 10.3989 19.3489 10.5343 19.3804 10.6959ZM17.9585 11.0264C16.8804 10.8345 15.7297 11.1554 14.8977 11.9875C13.8701 13.0151 13.6221 14.5285 14.1568 15.7911C14.2354 15.9769 14.1936 16.1917 14.051 16.3343L11.1926 19.1927C10.8056 19.5797 10.8056 20.2073 11.1928 20.5946C11.5801 20.9818 12.2077 20.9818 12.5947 20.5948L15.4531 17.7364C15.5957 17.5938 15.8105 17.552 15.9962 17.6306C17.2589 18.1653 18.7723 17.9173 19.7999 16.8897C20.632 16.0576 20.9529 14.907 20.761 13.8289L18.8963 15.6937C18.1224 16.4675 16.8678 16.4673 16.094 15.6934C15.3201 14.9196 15.3199 13.6649 16.0937 12.8911L17.9585 11.0264Z"
        />
      </svg>

      @if (tooltip(); as tooltip) {
        <div class="custom-marker-tooltip-container">
          {{ tooltip }}
        </div>
      }
    </div>
  `,
  styles: `
    .custom-marker-container {
      position: relative;
      cursor: pointer;
      /** bottom center as marker origin */
      transform: translate(-50%, -100%);

      .custom-marker-tooltip-container {
        display: none;
        user-select: none;
        background: var(--sdds-white);
        padding: 0.5rem;
        position: absolute;
        word-break: keep-all;
        left: calc(100% + 1rem);
        top: 0;
        white-space: nowrap;
        font-size: 1.2rem;
        color: var(--sdds-grey-958);
        font-family: var(--sdds-font-family-semi-condensed);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0.4rem;
      }

      #icon-container,
      #icon {
        transition: all 0.2s cubic-bezier(0.1, 0.9, 0.2, 1);
      }

      #icon-container {
        fill: var(--sdds-blue-600);
        stroke: white;
      }

      #icon {
        fill: white;
      }

      &:hover {
        .custom-marker-tooltip-container {
          display: block;
        }
      }
    }

    .custom-marker-container:hover,
    .here-map-marker-selected .custom-marker-container {
      #icon-container {
        fill: white;
        stroke: transparent;
      }

      #icon {
        fill: var(--sdds-blue-400);
      }
    }
  `
})
export class HereMapWorkshopMarkerComponent {
  tooltip = input<string>();
  selected = input<boolean>(false);
}
